<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="dialog"
      max-width="700px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title
          >{{ editedItem.wellname }}&nbsp;&nbsp;{{ editedItem.testname }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <datepicker
                label="Alternative Date"
                v-model="editedItem.event_date"
                :rules="[(v) => !!v || 'Waiver Date required']"
                :key="cs"
                :edit="editedItem.statut=='Task'"
                :date_min="editedItem.due_date" 
                @changes="changes++"
              ></datepicker>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                label="comment"
                return-object
                autocomplete="off"
                v-model="editedItem.comment"
                row-height="30"
                rows="2"
                variant="outlined"
                auto-grow
                outlined
                dense
                :readonly="editedItem.statut!='Task'"
                @input="changes++"
              >
              </v-textarea>
            </v-col>
      <uploader :key="kfile" :files_list="uploadedFiles"  :edit_file="editedItem.statut=='Task'"
                  @change_files="change_files" :uploading_file="uploading"   />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="changes==0" class="ma-1" color="blue darken-1" @click.stop="save()">
            Save
          </v-btn>
          <v-btn class="ma-1" color="blue darken-1" @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog></v-form
  >
</template>
<script>
//import EventCreate from "../graphql/Planning/EventCreate.gql";
//import EventUpdate from "../graphql/Planning/EventUpdate.gql";
import getFiles_list from "../graphql/Reparation/DOCS.gql"; 

import SaveWaiver from "../graphql/Planning/SaveWaiver.gql";
export default {
  components: {
    datepicker: () => import("../components/DatePicker.vue"),
   uploader: () => import("../components/FilesUploader.vue"),
  },
  props: {
    dialog: Boolean,
    waiver: Object, 
   
  },
  data() {
    return {
      changes:0,
      uploadedFiles:[],
      uploading:false,
      kfile:100000, 
      editedItem: {},
      cs: 1000,
      docs_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          sortable: false,
          index: 0,
        },
        {
          text: "Reference",
          value: "ref",
          enum: "REF",
          selected: true,
          index: 1,
        },
        {
          text: "Description",
          value: "description",
          enum: "DESCRIPTION",
          selected: true,
          index: 2,
        },
        {
          text: "File/Url",
          value: "filename",
          enum: "FILENAME",
          slot: "preview",
          preview: "preview",
          selected: true,
          index: 3,
        },
        {
          text: "Size (ko)",
          value: "size",
          enum: "SIZE",
          align: "end",
          slot: "cur33",
          selected: false,
          index: 5,
        },
        {
          text: "Date",
          value: "doc_date",
          enum: "DOC_DATE",
          slot: "date",
          selected: true,
          index: 6,
        },
        {
          text: "Url?",
          value: "isurl",
          slot: "checkbox",
          enum: "ISURL",
          selected: false,
          hiden: true,
          index: 7,
        },
        {
          text: "is Active?",
          value: "isActive",
          slot: "checkbox",
          enum: "ACTIVE",
          selected: false,
          hiden: true,
          index: 8,
        },
        {
          text: "User",
          value: "contact",
          enum: "CONTACT",
          selected: false,
          hiden: true,
          index: 9,
        },
        {
          text: "Test",
          value: "test",
          enum: "TEST",
          selected: false,
          hiden: true,
          index: 10,
        },
        {
          text: "",
          slot: "col_btn1",
          icon: "mdi-pencil",
          selected: true,
          width: "10",
          column: "create_uid",
          valeur: this.$store.state.me.id,
          tooltip: "Update document",
          sortable: false,
          index: 11,
        },
        {
          text: "",
          slot: "col_btn2",
          icon: "mdi-download",
          selected: true,
          width: "10",
          tooltip: "Download document",
          column1: "isurl",
          valeur1: 0,
          sortable: false,
          index: 12,
        },
      ],
    };
  },
  watch: {},
  mounted() {
    this.autorisations = this.$store.state.me.autorisations.split(","); 

    this.editedItem = Object.assign({}, this.waiver);
   
    if(this.waiver.id) this.getFiles();
    this.cs++;
    this.changes=0; 
  },
  computed: {},
  methods: {
  change_files(files) {
            this.uploadedFiles=files; 
        },
    closeDialog(item) {
      this.$emit("WaiverClose", item);
    },
 
    async save() { //
            if (this.$refs.form.validate()) {
                   let v = {
                    id:this.waiver.id && this.waiver.id>0?this.waiver.id:null,
                    test_id: this.editedItem.test_id,
                    event_date: this.editedItem.event_date,
                    comment: this.editedItem.comment,
                    planning_id: this.editedItem.planning_id,
                    operator_id: this.$store.state.me.id,
                    event_statut: this.editedItem.event_statut,
                    event_id: 1,
                  };
            
               this.uploadedFiles.forEach(x=>{
                    delete x["__typename"];
                      delete x["url"];
                });
           
                this.$apollo
                    .query({
                        query: SaveWaiver,
                        variables: {
                            waiver: v, 
                            files: this.uploadedFiles, 
                        },
                        fetchPolicy: "no-cache",
                    })
                    .then(() => { 
                        this.closeDialog(this.editedItem.planning_id, v);
                    })
                    .catch(() => {});
            }
    },
   async getFiles() {
            this.uploading=true; 
            let r = await this.$apollo.query({
                query: getFiles_list,
                variables: {
                    id: this.waiver.id,
                    table:"Waiver"
                },
                fetchPolicy: "no-cache",
            });
            if (r) {
                this.uploadedFiles = r.data.get_files; 

            }
             this.uploading=false;
             this.kfile++;

        },
  },
};
</script>
